import { getUserStoreState } from '@/services/userService';

export async function verifyCatalogAccess(vm) {
  const userStore = getUserStoreState();
  const { user } = userStore;
  if (!user) {
    return vm.$router.push(`/login?redirect=${vm.$route.fullPath}`);
  }

  const isDemoUrl = vm.$route.path.startsWith('/d/');

  const isCatalogPermissionDemo = user.permissions?.apps?.catalog?.isDemo;

  for (let key in user.permissions) {
    console.log('Perm: ' + key + ' value: ' + user.permissions[key]);
  }

  // The current catalog app is in the demo mode
  // Check if the user has a permission apps.catalog.isDemo
  // and if the algolia_index param matches
  if (
    isDemoUrl &&
    isCatalogPermissionDemo &&
    user.permissions?.apps?.catalog?.algoliaIndex?.includes(
      vm.$route.params.algolia_index?.toLowerCase()
    )
  ) {
    return;
  }

  // We are not on a demo URL, but the core /catalogs instead
  // Check if permissions.apps.catalog.isDemo is false.
  if (
    user.permissions?.apps?.catalog?.active &&
    !isCatalogPermissionDemo &&
    !isDemoUrl
  ) {
    return;
  }
  vm.$router.push({
    name: 'FORBIDDEN',
  });
  throw new Error('FORBIDDEN');
}
