<template>
  <vue-final-modal :value="true" name="select-publisher-modal" hide-overlay>
    <div :class="$style.selectCatalogContainer">
      <div :class="$style.selectCatalogContent">
        <!-- <h1 :class="$style.contentHeadline">Publishers</h1> -->
        <form :class="$style.searchForm">
          <input
            id="query"
            :class="$style.searchInput"
            name="query"
            type="text"
            v-model="query"
            placeholder="Search publishers..."
          />
          <BaseButton tag="router-link" to="/catalogs/search/map"
            >Search All</BaseButton
          >
        </form>
        <div v-if="fetchPublishersStatusPending && !publishers.length">
          <BaseSpinner show />
        </div>
        <div v-if="fetchPublishersStatusError">
          <p>
            There was a problem while fetching publishers. Try refreshing your
            browser or contact support.
          </p>
        </div>
        <div v-if="publishers.length">
          <div :class="$style.publishersTableContainer">
            <table :class="$style.publishersTable">
              <thead>
                <tr>
                  <th>Publisher</th>
                  <th>Works Found</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="publisher of publishers" :key="publisher.objectID">
                  <td>
                    <router-link
                      :title="publisher.originalName"
                      :to="getPublisherRouteLink(publisher)"
                      >{{ publisher.party }}</router-link
                    >
                  </td>
                  <td>
                    <span v-if="Number.isInteger(publisher.tracksCount)">{{
                      new Intl.NumberFormat("en-US", {}).format(
                        publisher.tracksCount
                      )
                    }}</span>
                  </td>
                </tr>
                <LoadMore
                  tag="tr"
                  @loadMore="!isLastPage && fetchPublishers(query, page + 1)"
                />
              </tbody>
            </table>
          </div>
          <form :class="$style.publishersFilters">
            <div>
              <input
                id="admin-publisher"
                v-model="publisherFilters.admin"
                type="checkbox"
              />
              <label for="admin-publisher"> Admin </label>
            </div>
            <div>
              <input
                id="sub-publisher"
                v-model="publisherFilters.sub"
                type="checkbox"
              />
              <label for="sub-publisher"> Sub Publisher </label>
            </div>
            <div>
              <input
                id="original-publisher"
                v-model="publisherFilters.original"
                type="checkbox"
              />
              <label for="original-publisher"> Original Publisher </label>
            </div>
          </form>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import { withAsync } from "@/helpers";
import { API_STATUS } from "@/constants/apiStatus";
import { upperFirst } from "lodash-es";
import LoadMore from "@/components/common/LoadMore";
import { fetchAndUpdatePublishersTracksCount } from "@/api/catalogApi";
import { truncate } from "@/helpers";
import { searchPublishers } from "../../../../api/publisherApi";

const { IDLE, PENDING, SUCCESS, ERROR } = API_STATUS;
export default {
  name: "SelectCatalogModal",
  components: {
    LoadMore,
  },
  data() {
    return {
      fetchPublishersStatus: IDLE,
      publishers: [],
      query: "",
      page: 0,
      isLastPage: 0,
      publisherFilters: {
        admin: false,
        original: false,
        sub: false,
      },
    };
  },
  created() {
    this.fetchPublishers("", 0);
  },
  watch: {
    query(q) {
      this.isLastPage = false;
      this.fetchPublishers(q, 0);
    },
  },
  computed: {
    ...apiStatusComputedFactory("fetchPublishersStatus"),
    publishersFiltersQuery() {
      let publishersFiltersParams = [];
      for (const [key, include] of Object.entries(this.publisherFilters)) {
        if (include) {
          publishersFiltersParams.push(key);
        }
      }

      if (!publishersFiltersParams.length) {
        return "&publisherFilters=";
      }

      return `&publisherFilters=${publishersFiltersParams.join("|")}`;
    },
  },
  methods: {
    getPublisherRouteLink(publisher) {
      const path = `/catalogs/search?q=${publisher.party}`;

      return `${path}${this.publishersFiltersQuery}`;
    },
    async fetchPublishers(query = "", page = 0) {
      this.fetchPublishersStatus = PENDING;
      const { response, error } = await withAsync(() =>
        searchPublishers({
          query,
          page,
        })
      );
      if (error) {
        this.fetchPublishersStatus = ERROR;
        return;
      }

      const newPublishers = response.hits.map((item) => {
        const normalisedPublisherName = String(item.publisher)
          .toLowerCase()
          .split(" ")
          .map((word) => upperFirst(word))
          .join(" ");
        return {
          ...item,
          party: truncate(normalisedPublisherName, 30),
          originalName: normalisedPublisherName,
        };
      });
      this.publishers =
        page === 0 ? newPublishers : [...this.publishers, ...newPublishers];
      this.page = page;
      if (response.nbPages === page) {
        this.isLastPage = true;
      }
      this.fetchPublishersStatus = SUCCESS;
      this.initFetchPublishersTracksCount(newPublishers);
    },
    async initFetchPublishersTracksCount(newPublishers) {
      const publishersWithoutCurrentCount = newPublishers.filter(
        (publisher) => {
          if (!Number.isInteger(publisher.tracksCount)) return true;
          const twelveHours = Date.now() - 60 * 60 * 12 * 1000;
          if (publisher.tracksCountUpdateTimestamp < twelveHours) {
            return true;
          }
          return false;
        }
      );

      if (!publishersWithoutCurrentCount.length) return;
      const { response, error } = await withAsync(() =>
        fetchAndUpdatePublishersTracksCount({
          publishers: publishersWithoutCurrentCount,
        })
      );

      const publishersWithCountsMap = response.reduce((acc, item) => {
        acc[item.objectID] = item;
        return acc;
      }, {});

      for (const publisher of this.publishers) {
        if (Reflect.has(publishersWithCountsMap, publisher.objectID)) {
          this.$set(
            publisher,
            "tracksCount",
            publishersWithCountsMap[publisher.objectID].tracksCount
          );
          this.$set(
            publisher,
            "tracksCountUpdateTimestamp",
            publishersWithCountsMap[publisher.objectID]
              .tracksCountUpdateTimestamp
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" module>
.selectCatalogContainer {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.selectCatalogContent {
  background: white;
  width: 100%;
  max-height: 600px;
  padding: 2rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(59, 44, 44, 0.06);
  border: 1px solid #e5e7eb;

  @media (min-width: 768px) {
    max-width: 600px;
  }
}

.contentHeadline {
  margin-bottom: 1.5rem;
}

.searchForm {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .searchInput {
    font-size: 2.4rem;
    font-weight: bold;
    font-family: $primary-font;
    border: none;
    background: transparent;
  }
}

.publishersFilters {
  display: flex;
  justify-content: space-between;
  padding-top: 1.5rem;
}

.publishersTableContainer {
  overflow-y: auto;
  max-height: 400px;
}

.publishersTable {
  thead th {
    display: table-cell !important;
    padding: 0;
    color: $color-primary;
  }
}
</style>